@import "../../../global/style.scss";

.supervisors_section {
  background-color: white;
  .supervisors_container {
    // padding: 6rem 6rem 6rem 6rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
    width: 100%;
    position: relative;
    .supervisors_title {
      text-align: center;
      font-size: 4.5rem;
      font-family: Mont-Heavy;
      color: $light_green;
      margin-bottom: 4rem;
    }
    .supervisors_card_wrapper {
      max-width: 65rem;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      .supervisor_card {
        max-width: 20rem;
        margin: 2rem auto;
        padding: 1rem;
        text-align: center;
        border-radius: 0.5rem;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        .supervisor_img {
          height: 10rem;
          width: auto;
        }
        .supervisor_info_wrapper {
          margin-top: 0.8rem;
          height: 12rem;
          .name {
            height: 4.4rem;
            color: $light_green;
            font-size: 1.4rem;
            font-weight: 700;
            margin-bottom: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .designation {
            font-size: 1.2rem;
            font-weight: 700;
            margin-bottom: 0.5rem;
          }
          .department,
          .institution {
            font-weight: 400;
          }
        }
      }
    }
  }
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .supervisors_section {
    .supervisors_container {
      padding-top: 3rem;
      padding-bottom: 3rem;
      .supervisors_title {
        font-size: 3rem;
      }
      .supervisors_card_wrapper {
        display: inherit;
        .supervisor_card {
          max-width: 100%;
        }
      }
    }
  }
}
/* -------- sm -------- */
@media (min-width: 576px) and (max-width: 767.98px) {
  .supervisors_section {
    .supervisors_container {
      .supervisors_title {
        font-size: 4rem;
      }
      .supervisors_card_wrapper {
        display: inherit;
        .supervisor_card {
          max-width: 22rem;
        }
      }
    }
  }
}
/* -------- md -------- */
@media (min-width: 768px) and (max-width: 991.98px) {
  .supervisors_section {
    .supervisors_container {
      .supervisors_title {
        font-size: 4.5rem;
      }
      .supervisors_card_wrapper {
        .supervisor_card {
          max-width: 13.5rem;
        }
      }
    }
  }
}
/* -------- l -------- */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .supervisors_section {
    .supervisors_container {
      .supervisors_card_wrapper {
        max-width: 65rem;
        .supervisor_card {
          max-width: 15rem;
        }
      }
    }
  }
}
/* -------- xl -------- */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .supervisors_section {
    .supervisors_container {
      .supervisors_card_wrapper {
        max-width: 65rem;
        .supervisor_card {
          max-width: 19.5rem;
        }
      }
    }
  }
}
/* -------- xxl -------- */
@media (min-width: 1400px) {
}
