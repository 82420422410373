@import "../../../global/style.scss";

.papers_section {
  background-color: white;
  .papers_container {
    padding-top: 6rem;
    padding-bottom: 6rem;
    width: 100%;
    position: relative;
    .papers_title {
      text-align: center;
      font-size: 4.5rem;
      font-family: Mont-Heavy;
      color: $light_green;
      margin-bottom: 4rem;
    }
    .papers_slider_container {
      .paper_card_wrapper {
        padding: 2rem 0.8rem;
        .paper_card_link {
          text-decoration: none;
          color: inherit;
          .paper_card {
            height: 34.375rem;
            border-radius: 0.5rem;
            overflow-x: hidden;
            overflow-y: hidden;
            transition: all 0.3s ease-out;
            border: 1px solid rgb(192, 192, 192);
            .paper_img {
              width: 100%;
              height: 13.87rem;
              border-top-right-radius: 0.5rem;
              border-top-left-radius: 0.5rem;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center center;
              pointer-events: none;
            }
            .paper_card_body {
              margin: 1.5rem 1.5rem;
              .paper_title {
                color: $dark_green;
                font-size: 1.2rem;
                font-family: Mont-Bold;
                margin-bottom: 1rem;
                transition: all 0.2s ease-out;
              }
              .paper_authors {
                font-family: Mont-Regular;
              }
            }
          }
          &:hover {
            .paper_card {
              transform: translateY(-16px);
              .paper_card_body {
                .paper_title {
                  color: $light_green;
                }
              }
            }
          }
        }
      }
    }
    .indicator {
      margin-top: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .control_btn {
        border: none;
        outline: none;
        background-color: transparent;
        .arrow_left,
        .arrow_right {
          width: auto;
          height: 1.2rem;
          margin: 0.5rem;
          cursor: pointer;
        }
      }

      .slide_dots_container {
        display: flex;
        margin: 0 0.6rem;
        .indicator_circle {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          margin: 0 0.4rem;
        }
        .circle_border {
          border: 2px solid $light_green;
        }
        .circle_bg {
          background-color: $light_green;
        }
      }
    }
  }
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .papers_section {
    .papers_container {
      padding-top: 3rem;
      padding-bottom: 3rem;
      .papers_title {
        font-size: 3rem;
      }
    }
  }
}
/* -------- sm -------- */
@media (min-width: 576px) and (max-width: 767.98px) {
  .papers_section {
    .papers_container {
      .papers_title {
        font-size: 4rem;
      }
    }
  }
}
/* -------- md -------- */
@media (min-width: 768px) and (max-width: 991.98px) {
  .papers_section {
    .papers_container {
      .papers_title {
        font-size: 4.5rem;
      }
    }
  }
}
