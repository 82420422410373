@font-face {
  font-family: "Mont-Black"; /*Can be any text*/
  src: local("Mont-Black"), url("./assets/fonts/Mont-Black.woff") format("woff"),
    url("./assets/fonts/Mont-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Mont-Bold"; /*Can be any text*/
  src: local("Mont-Bold"), url("./assets/fonts/Mont-Bold.woff") format("woff"),
    url("./assets/fonts/Mont-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Mont-Book"; /*Can be any text*/
  src: local("Mont-Book"), url("./assets/fonts/Mont-Book.woff") format("woff"),
    url("./assets/fonts/Mont-Book.ttf") format("truetype");
}

@font-face {
  font-family: "Mont-ExtraLight"; /*Can be any text*/
  src: local("Mont-ExtraLight"),
    url("./assets/fonts/Mont-ExtraLight.woff") format("woff"),
    url("./assets/fonts/Mont-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Mont-Heavy"; /*Can be any text*/
  src: local("Mont-Heavy"), url("./assets/fonts/Mont-Heavy.woff") format("woff"),
    url("./assets/fonts/Mont-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "Mont-Light"; /*Can be any text*/
  src: local("Mont-Light"), url("./assets/fonts/Mont-Light.woff") format("woff"),
    url("./assets/fonts/Mont-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Mont-Regular"; /*Can be any text*/
  src: local("Mont-Regular"),
    url("./assets/fonts/Mont-Regular.woff") format("woff"),
    url("./assets/fonts/Mont-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Mont-SemiBold"; /*Can be any text*/
  src: local("Mont-SemiBold"),
    url("./assets/fonts/Mont-SemiBold.woff") format("woff"),
    url("./assets/fonts/Mont-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Mont-Thin"; /*Can be any text*/
  src: local("Mont-Thin"), url("./assets/fonts/Mont-Thin.woff") format("woff"),
    url("./assets/fonts/Mont-Thin.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Mont-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
