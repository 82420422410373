@import "../../global/style.scss";

.news_section {
  .news_container {
    .news_section_title {
      text-align: center;
      font-size: 5.5rem;
      font-family: Mont-Heavy;
      color: $light_green;
      margin-bottom: 4rem;
      padding-top: 10rem;
    }
    .news_main {
      width: 100%;
      padding-top: 2rem;
      .news_block {
        display: flex;
        padding-bottom: 4rem;
        .news_text_block {
          width: 65%;
          padding-right: 3rem;
          a {
            text-decoration: none;
          }
          .news_title {
            color: $light_green;
            font-size: 1.8rem;
            font-family: Mont-Bold;
          }
          .news_description {
            font-size: 1.1rem;
            padding-top: 1rem;
          }
        }
        .news_img {
          width: 35%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .news_section {
    .news_container {
      .news_section_title {
        font-size: 3rem;
        margin-bottom: 2rem;
      }
      .news_main {
        .news_block {
          display: block;
          padding-bottom: 4rem;
          .news_text_block {
            width: 100%;
            padding-right: 0;
            .news_title {
              font-size: 1.5rem;
            }
            .news_description {
              font-size: 1rem;
            }
          }
          .news_img {
            width: 100%;
            padding-top: 2rem;
          }
        }
      }
    }
  }
}

/* -------- sm -------- */
@media (min-width: 576px) and (max-width: 767.98px) {
  .news_section {
    .news_container {
      .news_section_title {
        font-size: 4rem;
        margin-bottom: 2rem;
      }
      .news_main {
        .news_block {
          display: block;
          padding-bottom: 4rem;
          .news_text_block {
            width: 100%;
            padding-right: 0;
            .news_description {
              font-size: 1rem;
            }
          }
          .news_img {
            width: 100%;
            padding-top: 2rem;
          }
        }
      }
    }
  }
}

/* -------- md -------- */
@media (min-width: 768px) and (max-width: 991.98px) {
  .news_section {
    .news_container {
      .news_main {
        .news_block {
          .news_text_block {
            width: 60%;
            .news_description {
              font-size: 1rem;
            }
          }
          .news_img {
            width: 40%;
          }
        }
      }
    }
  }
}

/* -------- l -------- */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .news_section {
    .news_container {
      .news_main {
        .news_block {
          .news_text_block {
            width: 60%;
          }
          .news_img {
            width: 40%;
          }
        }
      }
    }
  }
}

/* -------- xl -------- */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

/* -------- xxl -------- */
@media (min-width: 1400px) {
}
