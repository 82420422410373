@import '../../global/style.scss';

.navbar_list_style {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
}
.navbar_list {
  display: flex;
  align-items: center;
}
.navbar_list {
  .navbar_logo {
    margin-right: auto;
    a {
      text-decoration: none;
      .navbar_logo_img {
        width: 5rem;
        height: auto;
        filter: drop-shadow(2px 3px 1.5px rgb(0 0 0 / 0.4));
        transition: all 0.2s ease-out;
      }
      .img_after_scroll {
        width: 3.5rem;
      }
    }
  }
}
.navbar_item {
  font-size: 1.2rem;
  color: inherit;

  cursor: pointer;
  a {
    color: inherit;
    text-decoration: none;
    position: relative;
    transition: all 0.2s ease-out;
    &::after {
      position: absolute;
      display: block;
      content: '';
      height: 2px;
      width: 0;
      background-color: $light_green;
      transition: all 0.2s ease-out;
    }
    &:hover {
      color: $light_green;
      &::after {
        width: 100%;
      }
    }
  }
}
.navbar_item_margin {
  margin-right: 1.875rem;
}
.navbar_menu_container {
  position: relative;
  .navbar_menu_wrapper {
    padding: 1.2rem;
    animation: appear 0.2s ease-out;
    border-radius: 0.5rem;
    color: black;
    background: white;
    position: absolute;
    top: 0;
    right: 0;
    .navbar_menu_item {
      margin: 0.4rem 0;
    }
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
