.intro_title {
  display: inline-block;
  h1 {
    display: inline-block;
    position: relative;
    bottom: -1em;
    opacity: 0;
    font-family: Mont-Heavy;
    font-size: 7.6rem;
    line-height: 7.4rem;
    color: #fff;
    animation: move-text-color 0.75s forwards;
    z-index: 1000;
  }
}

@keyframes move-text-color {
  0% {
    bottom: -0.4em;
    opacity: 1;
  }

  50% {
    bottom: 0em;
  }

  100% {
    bottom: 0em;
    opacity: 1;
  }
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .intro_title {
    h1 {
      font-size: 14vw;
      line-height: 14vw;
    }
  }
}
/* -------- sm -------- */
@media (min-width: 576px) and (max-width: 767.98px) {
  .intro_title {
    h1 {
      font-size: 5.4rem;
      line-height: 5.4rem;
    }
  }
}
/* -------- md -------- */
@media (min-width: 768px) and (max-width: 991.98px) {
  .intro_title {
    h1 {
      font-size: 7rem;
      line-height: 7rem;
    }
  }
}
