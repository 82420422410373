@import "../../global/style.scss";

.molbionews_section {
  .molbionews_container {
    padding-bottom: 4rem;
    .molbionews_section_title {
      text-align: center;
      font-size: 5.5rem;
      font-family: Mont-Heavy;
      color: $light_green;
      margin-bottom: 4rem;
      padding-top: 10rem;
    }
    .molbionews_main {
      width: 100%;
      padding-top: 2rem;
      .molbionews_articles_container {
        padding-bottom: 4rem;
        .molbionews_articles_container_title {
          font-size: 2.5rem;
          font-family: Mont-Bold;
          padding-bottom: 2.5rem;
          text-align: center;
        }
        .molbionews_articles_container_inner {
          display: flex;
          flex-wrap: wrap;
          .molbionews_article {
            width: 25%;
            padding: 1.5rem 1rem;
            .molbionews_article_img {
              width: 100%;
              background-image: url("https://images.unsplash.com/photo-1656925341707-d2a2e6c25010");
              height: 17rem;
              background-position: center center;
              background-size: cover;
              background-repeat: no-repeat;
            }
            a {
              text-decoration: none;
              .molbionews_article_title {
                padding-top: 2rem;
                font-size: 1.2rem;
                font-family: Mont-Semibold;
                color: $light_green;
              }
            }
          }
        }
      }

      .videos_container {
        .videos_container_title {
          font-size: 2.5rem;
          font-family: Mont-Bold;
          padding-bottom: 2.5rem;
          text-align: center;
        }
        .videos_container_inner {
          display: flex;
          flex-wrap: wrap;
          .video_item {
            width: 25%;
            padding: 1.5rem 1rem;
            .video_item_inner {
              width: 100%;
              background-color: black;
              display: flex;
              justify-content: center;
              align-items: center;
              .video {
                width: 100%;
              }
            }

            .video_title {
              padding-top: 2rem;
              font-size: 1.2rem;
              font-family: Mont-Semibold;
              color: $light_green;
            }
          }
        }
      }
    }
  }
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .molbionews_section {
    .molbionews_container {
      .molbionews_section_title {
        font-size: 3rem;
        margin-bottom: 2rem;
      }
      .molbionews_main {
        .molbionews_articles_container {
          .molbionews_articles_container_title {
            font-size: 2rem;
            padding-bottom: 1.5rem;
          }
          .molbionews_articles_container_inner {
            .molbionews_article {
              width: 100%;
              padding: 1.5rem 0;
              .molbionews_article_title {
                font-size: 1rem;
                padding-top: 1.5rem;
              }
            }
          }
        }

        .videos_container {
          .videos_container_title {
            font-size: 2rem;
            padding-bottom: 1.5rem;
          }
          .videos_container_inner {
            .video_item {
              width: 100%;
              padding: 1.5rem 0;
              .video_title {
                font-size: 1rem;
                padding-top: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}

/* -------- sm -------- */
@media (min-width: 576px) and (max-width: 767.98px) {
  .molbionews_section {
    .molbionews_container {
      .molbionews_main {
        .molbionews_articles_container {
          .molbionews_articles_container_inner {
            .molbionews_article {
              width: 100%;
            }
          }
        }

        .videos_container {
          .videos_container_inner {
            .video_item {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

/* -------- md -------- */
@media (min-width: 768px) and (max-width: 991.98px) {
  .molbionews_section {
    .molbionews_container {
      .molbionews_main {
        .molbionews_articles_container {
          .molbionews_articles_container_inner {
            .molbionews_article {
              width: 50%;
            }
          }
        }

        .videos_container {
          .videos_container_inner {
            .video_item {
              width: 50%;
            }
          }
        }
      }
    }
  }
}

/* -------- l -------- */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .molbionews_section {
    .molbionews_container {
      .molbionews_main {
        .molbionews_articles_container {
          .molbionews_articles_container_inner {
            .molbionews_article {
              width: 50%;
            }
          }
        }

        .videos_container {
          .videos_container_inner {
            .video_item {
              width: 50%;
            }
          }
        }
      }
    }
  }
}

/* -------- xl -------- */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .molbionews_section {
    .molbionews_container {
      .molbionews_main {
        .molbionews_articles_container {
          .molbionews_articles_container_inner {
            .molbionews_article {
              width: 33.33%;
            }
          }
        }

        .videos_container {
          .videos_container_inner {
            .video_item {
              width: 33.33%;
            }
          }
        }
      }
    }
  }
}

/* -------- xxl -------- */
@media (min-width: 1400px) {
}
