@import "../../global/style.scss";

.gallery_page_section {
  .gallery_page_container {
    .gallery_page_title {
      text-align: center;
      font-size: 5.5rem;
      font-family: Mont-Heavy;
      color: $light_green;
      margin-bottom: 4rem;
      padding-top: 10rem;
    }
    .gallery_page_main {
      width: 100%;
      background: #fff;
      columns: 3;
      column-gap: 20px;
      padding-top: 2rem;
      .photo_container {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          margin-bottom: 20px;
          border-radius: 3px;
        }
      }
    }
  }
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .gallery_page_section {
    .gallery_page_container {
      .gallery_page_title {
        font-size: 3rem;
      }
      .gallery_page_main {
        columns: 1;
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .gallery_page_section {
    .gallery_page_container {
      .gallery_page_main {
        columns: 2;
      }
    }
  }
}
