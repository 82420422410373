@import "../global/style.scss";

.loader_wrapper {
  .loader_container {
    min-height: 100vh;
    width: 100%;
    position: relative;
    background-color: white;
    .loader_inside {
      .loading {
        display: inline-block;
        width: 70px;
        height: 70px;
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        border: 8px solid rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        border-top-color: $light_green;
        animation: spin 1s ease-in-out infinite;
        -webkit-animation: spin 1s ease-in-out infinite;
      }
    }
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
