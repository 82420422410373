@import "../../../global/style.scss";

.about_section {
  background-image: white;
  overflow: hidden;
  .about_container {
    width: 100%;
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-left: 6rem;
    padding-right: 0.75rem;
    margin-left: auto;
    transition: all 0.5s ease;
    .about_inner {
      height: 100%;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      .about_text_container {
        .about_text {
          font-size: 1.5rem;
          font-family: Mont-Regular;
          margin-right: 2rem;
          text-align: justify;
          a {
            text-decoration: none;
            color: inherit;
            color: $light_green;
            margin-left: 0.5rem;
          }
        }
      }
      .about_right_container {
        padding-right: 0;
        text-align: right;
        position: relative;
        .about_title {
          height: 36rem;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin-top: auto;
          margin-bottom: auto;
          color: $light_green;
          font-size: 9.5rem;
          font-family: Mont-Heavy;
          line-height: 12.5rem;
        }
        .about_img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .about_section {
    .about_container {
      padding-left: 0.938rem;
      padding-top: 3rem;
      padding-bottom: 3rem;
      margin-left: auto;
      margin-right: auto;
      width: 95%;
      .about_inner {
        display: block;
        .about_text_container {
          width: 100%;
          .about_text {
            font-size: 1.1rem;
            margin-right: 0;
            text-align: justify;
          }
        }
        .about_right_container {
          width: 100%;
          .about_title {
            top: 4rem;
            height: 100%;
            margin-top: auto;
            margin-bottom: auto;
            color: $light_green;
            font-size: 18vw;
            line-height: 18vw;
          }
          .about_img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
/* -------- sm -------- */
@media (min-width: 576px) and (max-width: 767.98px) {
  .about_section {
    .about_container {
      padding-left: 0.938rem;
      margin-left: auto;
      margin-right: auto;
      width: 33.75rem;
      .about_inner {
        display: block;
        .about_text_container {
          width: 100%;
          margin-top: 2rem;
          .about_text {
            font-size: 1.2rem;
            margin-right: 0;
          }
        }
        .about_right_container {
          width: 100%;
          .about_title {
            top: 4rem;
            height: 100%;
            margin-top: auto;
            margin-bottom: auto;
            color: $light_green;
            font-size: 7rem;
            line-height: 7rem;
          }
        }
      }
    }
  }
}
/* -------- md -------- */
@media (min-width: 768px) and (max-width: 991.98px) {
  .about_section {
    .about_container {
      padding-left: 0.5rem;
      margin-left: auto;
      margin-right: auto;
      width: 45rem;
      .about_inner {
        display: block;
        .about_text_container {
          width: 100%;
          margin-top: 2rem;
          .about_text {
            font-size: 1.4rem;
            margin-right: 0;
          }
        }
        .about_right_container {
          width: 100%;
        }
      }
    }
  }
}
/* -------- l -------- */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .about_section {
    .about_container {
      width: 65.25rem;
      padding-right: 6rem;
      .about_inner {
        display: block;
        .about_text_container {
          width: 100%;
          margin-top: 2rem;
          .about_text {
            font-size: 1.4rem;
          }
        }
        .about_right_container {
          width: 100%;
          .about_title {
            height: 30.5rem;
            font-size: 8.5rem;
            line-height: 10.5rem;
          }
        }
      }
    }
  }
}
/* -------- xl -------- */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .about_section {
    .about_container {
      width: 100%;
      padding-right: 6rem;
      .about_inner {
        display: block;
        .about_text_container {
          width: 100%;
          margin-top: 2rem;
          .about_text {
            font-size: 1.4rem;
            margin-right: 0;
          }
        }
        .about_right_container {
          width: 100%;
          .about_title {
            height: 30.5rem;
            font-size: 10.5rem;
            line-height: 10.5rem;
          }
          .about_img {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
}
/* -------- xxl -------- */
@media (min-width: 1400px) {
}
