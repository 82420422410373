@import "../../../global/style.scss";

.contact_section {
  background-color: $light_green;
  .contact_container {
    padding: 6rem 3rem 4rem 2rem;
    .contact_title_common {
      color: white;
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 0.8rem;
    }
    .contact_us_wrapper {
      .contact_us_info {
        p,
        a {
          color: white;
          font-size: 1rem;
        }
      }
      a {
        text-decoration: none;
        color: white;
      }
    }
    .follow_us_title {
      margin-top: 3rem;
    }
    .follow_us_icons {
      img {
        width: 1.5rem;
        margin-right: 1rem;
      }
    }
  }
  .quick_links_wrapper {
    text-align: center;
    .quick_links_info {
      color: white;
      font-size: 1.1rem;
      display: flex;
      flex-direction: column;
      p {
        display: inline-block;

        a {
          display: inline-block;
          text-decoration: none;
          color: white;
          font-size: 1rem;
        }
      }
    }
  }
  .find_us_wrapper {
    text-align: right;
    .find_us_info {
      color: white;
      color: white;
      font-size: 1rem;
    }
    .institution_logo {
      margin-top: 2rem;
      img {
        width: auto;
        height: 4.5rem;
        margin-left: 1.5rem;
        filter: drop-shadow(2px 3px 1.5px rgba(0, 0, 0, 0.4));
      }
    }
  }
  footer {
    text-align: center;
    color: white;
    font-size: 0.9rem;
    font-family: Mont-Regular;
    margin-top: 6rem;
    .hr_line {
      max-width: 10rem;
      height: 1px;
      background-color: white;
      margin: 1rem auto 0 auto;
    }
    .creator_1,
    .creator_2 {
      margin-top: 1rem;
      font-size: 0.9rem;
      a {
        text-decoration: none;
        color: inherit;
      }
    }
    .creator_2 {
      display: none;
    }
  }
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .contact_section {
    .contact_container {
      .contact_us_wrapper {
        text-align: center;
        margin-bottom: 2rem;
        .follow_us_icons {
          img {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }
        }
      }
      .quick_links_wrapper {
        margin-bottom: 2rem;
      }
      .find_us_wrapper {
        text-align: center;
        margin-bottom: 2rem;
        .institution_logo {
          text-align: center;
          img {
            margin-right: 0.75rem;
            margin-left: 0.75rem;
          }
        }
      }
      footer {
        .creator_1 {
          display: none;
        }
        .creator_2 {
          display: block;
        }
      }
    }
  }
}
/* -------- sm -------- */
@media (min-width: 576px) and (max-width: 767.98px) {
  .contact_section {
    .contact_container {
      .contact_us_wrapper {
        text-align: center;
        margin-bottom: 2rem;
        .follow_us_icons {
          img {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }
        }
      }
      .quick_links_wrapper {
        margin-bottom: 2rem;
      }
      .find_us_wrapper {
        text-align: center;
        margin-bottom: 2rem;
        .institution_logo {
          text-align: center;
          img {
            margin-right: 0.75rem;
            margin-left: 0.75rem;
          }
        }
      }
      footer {
        .creator_1 {
          display: none;
        }
        .creator_2 {
          display: block;
        }
      }
    }
  }
}
/* -------- md -------- */
@media (min-width: 768px) and (max-width: 991.98px) {
}
/* -------- l -------- */
@media (min-width: 992px) and (max-width: 1199.98px) {
}
/* -------- xl -------- */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}
/* -------- xxl -------- */
@media (min-width: 1400px) {
}
