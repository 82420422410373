@import "../../global/style.scss";

.ourlab {
  .ourlab_container {
    .ourlab_title {
      text-align: center;
      font-size: 5.5rem;
      font-family: Mont-Heavy;
      color: $light_green;
      margin-bottom: 4rem;
      padding-top: 10rem;
    }
    .ourlab_main {
      width: 95% !important;
      margin-top: 3rem;
      text-align: justify;
      margin: 4rem auto;
      padding: 3rem;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      .ourlab_main_text {
        font-size: 1.2rem;
      }
    }
  }
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .ourlab {
    .ourlab_container {
      .ourlab_title {
        font-size: 3rem;
      }
      .ourlab_main {
        // margin-top: 2rem;
        font-size: 1rem;
        text-align: justify;
        margin: 3rem auto;
        padding: 1.5rem;
      }
    }
  }
}
