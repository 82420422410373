@import "../../global/style.scss";

.news_single_section {
  .news_single_container {
    .news_single_main {
      .news_single_block {
        .news_single_title {
          color: $light_green;
          font-size: 2.5rem;
          font-family: Mont-Bold;
          padding-top: 12rem;
          padding-bottom: 3rem;
        }
        .news_single_description {
          font-size: 1.1rem;
          figure {
            img {
              width: 100%;
              max-width: 60rem;
            }
          }
        }
      }
    }
  }
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .news_single_section {
    .news_single_container {
      .news_single_main {
        .news_single_block {
          .news_single_title {
            font-size: 1.5rem;
            padding-bottom: 2rem;
          }
          .news_single_description {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

/* -------- sm -------- */
@media (min-width: 576px) and (max-width: 767.98px) {
  .news_single_section {
    .news_single_container {
      .news_single_main {
        .news_single_block {
          .news_single_title {
            font-size: 2rem;
          }
          .news_single_description {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}

/* -------- md -------- */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* -------- l -------- */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* -------- xl -------- */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

/* -------- xxl -------- */
@media (min-width: 1400px) {
}
