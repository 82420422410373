@import "../../global/style.scss";

.labmembers_page_section {
  .labmembers_page_container {
    .labmembers_page_title {
      text-align: center;
      font-size: 5.5rem;
      font-family: Mont-Heavy;
      color: $light_green;
      margin-bottom: 4rem;
      padding-top: 10rem;
    }
    .labmembers_page_main {
      .labmembers_btn_wrapper {
        text-align: center;
        .labmembers_btn {
          font-size: 1.1rem;
          font-family: Mont-Regular;
          background-color: white;
          border: 0.2rem solid $light_green;
          border-radius: 0.5rem;
          outline: none;
          padding: 0.5rem;
          margin-left: 1rem;
          margin-right: 1rem;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          transition: all 0.2s ease-in-out;
          &:hover {
            color: white;
            background-color: $light_green;
          }
        }
        .labmembers_btn_clicked {
          color: white;
          background-color: $light_green;
        }
      }
      .labmembers_card_wrapper {
        max-width: 80rem;
        min-height: 100vh;
        margin: 4rem auto 3rem auto;
        .labmembers_card_container {
          animation: fadeIn 0.5s ease-out;
          .member_card {
            max-width: 20rem;
            // height: 22.5rem;
            margin: 1rem auto;
            padding: 1.5rem;
            text-align: center;
            border-radius: 0.5rem;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            .member_img {
              height: 10rem;
              width: auto;
              border-radius: 0.5rem;
            }
            .member_info_wrapper {
              margin-top: 0.8rem;
              .name {
                height: 4.4rem;
                color: $light_green;
                font-size: 1.4rem;
                font-weight: 700;
                margin-bottom: 0.3rem;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .designation {
                font-size: 1.2rem;
                font-weight: 700;
              }
              .email {
                height: 2.8rem;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .labmembers_page_section {
    .labmembers_page_container {
      .labmembers_page_title {
        font-size: 3rem;
      }
      .labmembers_page_main {
        .labmembers_btn_wrapper {
          text-align: center;
          .labmembers_btn {
            font-size: 1.1rem;
            font-family: Mont-Regular;
            background-color: white;
            border: 0.2rem solid $light_green;
            border-radius: 0.5rem;
            outline: none;
            padding: 0.5rem;
            margin-left: 1rem;
            margin-right: 1rem;
            margin-bottom: 1rem;
          }
          .labmembers_btn_clicked {
            color: white;
            background-color: $light_green;
          }
        }
        .labmembers_card_wrapper {
          max-width: 80rem;
          margin: 4rem auto 3rem auto;
          .member_card {
            max-width: 20rem;
            // height: 22.5rem;
            margin: 2rem auto;
            padding: 1.5rem;
            text-align: center;
            border-radius: 0.5rem;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
              rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            .member_img {
              height: 10rem;
              width: auto;
              border-radius: 0.5rem;
            }
            .member_info_wrapper {
              margin-top: 0.8rem;
              .name {
                height: 4.4rem;
                color: $light_green;
                font-size: 1.4rem;
                font-weight: 700;
                margin-bottom: 0.3rem;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .designation {
                font-size: 1.2rem;
                font-weight: 700;
              }
              .email {
                height: 2.8rem;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }
}
/* -------- sm -------- */
@media (min-width: 576px) and (max-width: 767.98px) {
}
/* -------- md -------- */
@media (min-width: 768px) and (max-width: 991.98px) {
  .labmembers_page_section {
    .labmembers_page_container {
      .labmembers_page_main {
        .labmembers_card_wrapper {
          .col-sm-md-4 {
            width: 50%;
          }
        }
      }
    }
  }
}
/* -------- l -------- */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .labmembers_page_section {
    .labmembers_page_container {
      .labmembers_page_main {
        .labmembers_card_wrapper {
          .col-sm-md-4 {
            width: 50%;
          }
        }
      }
    }
  }
}
/* -------- xl -------- */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .labmembers_page_section {
    .labmembers_page_container {
      .labmembers_page_main {
        .labmembers_card_wrapper {
          .col-sm-md-4 {
            width: 33.33%;
          }
        }
      }
    }
  }
}
/* -------- xxl -------- */
@media (min-width: 1400px) {
}
