@import "../../global/style.scss";

.publications_section {
  .publications_container {
    .publications_title {
      text-align: center;
      font-size: 5.5rem;
      font-family: Mont-Heavy;
      color: $light_green;
      margin-bottom: 4rem;
      padding-top: 10rem;
    }
    .publications {
      width: 100%;
      padding-top: 2rem;
      .publications_block {
        display: flex;
        padding-bottom: 2rem;
        .numbering {
          color: $light_green;
          font-size: 1.8rem;
          font-family: Mont-Bold;
        }
        .publications_main {
          padding-left: 1rem;
          a {
            text-decoration: none;
            .paper_title {
              color: $light_green;
              font-size: 1.8rem;
              font-family: Mont-Bold;
            }
          }

          .paper_authors {
            font-size: 1.1rem;
            padding-top: 0.5rem;
          }
        }
      }
    }
  }
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .publications_section {
    .publications_container {
      .publications_title {
        font-size: 3rem;
        margin-bottom: 2rem;
      }
      .publications {
        .publications_block {
          padding-bottom: 2rem;
          .numbering {
            font-size: 1.4rem;
          }
          .publications_main {
            padding-left: 0.5rem;
            a {
              .paper_title {
                font-size: 1.4rem;
              }
            }
            .paper_authors {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}

/* -------- sm -------- */
@media (min-width: 576px) and (max-width: 767.98px) {
  .publications_section {
    .publications_container {
      .publications_title {
        font-size: 4rem;
        margin-bottom: 2rem;
      }
      .publications {
        .publications_block {
          padding-bottom: 2rem;
          .numbering {
            font-size: 1.4rem;
          }
          .publications_main {
            a {
              .paper_title {
                font-size: 1.4rem;
              }
            }
            .paper_authors {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}

/* -------- md -------- */
@media (min-width: 768px) and (max-width: 991.98px) {
  .publications_section {
    .publications_container {
      .publications {
        .publications_block {
          padding-bottom: 2rem;
          .numbering {
            font-size: 1.6rem;
          }
          .publications_main {
            a {
              .paper_title {
                font-size: 1.6rem;
              }
            }
            .paper_authors {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

/* -------- l -------- */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .publications_section {
    .publications_container {
      .publications {
        .publications_block {
          padding-bottom: 2rem;
          .numbering {
            font-size: 1.6rem;
          }
          .publications_main {
            a {
              .paper_title {
                font-size: 1.6rem;
              }
            }
            .paper_authors {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

/* -------- xl -------- */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

/* -------- xxl -------- */
@media (min-width: 1400px) {
}
