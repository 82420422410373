@import '../../global/style.scss';

header {
  padding: 0.6875rem 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  color: white;
  z-index: 999;
  transition: background-color 0.2s ease-out;
  .navbar_container {
    width: 95%;
    // max-width: 960px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 0.9375rem;
    .menubar_pc {
    }
    .menubar_mobile {
      display: none;
    }
  }
}

.header_style {
  color: black !important;
  background: white;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  header {
    .navbar_container {
      max-width: 720px;
      .menubar_pc {
        display: none;
      }
      .menubar_mobile {
        display: block;
      }
    }
  }
}
/* -------- sm -------- */
@media (min-width: 576px) and (max-width: 767.98px) {
  header {
    .navbar_container {
      max-width: 720px;
      .menubar_pc {
        display: none;
      }
      .menubar_mobile {
        display: block;
      }
    }
  }
}
/* -------- md -------- */
@media (min-width: 768px) and (max-width: 991.98px) {
}
/* -------- l -------- */
@media (min-width: 992px) and (max-width: 1199.98px) {
}
/* -------- xl -------- */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}
/* -------- xxl -------- */
@media (min-width: 1400px) {
}
