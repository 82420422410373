@import "../../../global/style.scss";

@mixin circle_container(
  $vertical_prop,
  $horizontal_prop,
  $vertical_value,
  $horizontal_value
) {
  position: absolute;
  #{$vertical_prop}: $vertical_value;
  #{$horizontal_prop}: $horizontal_value;
}

@mixin circle_main(
  $width,
  $height,
  $border_width: auto,
  $color: auto,
  $anim_name: auto,
  $anim_duration: auto
) {
  width: $width;
  height: $height;
  border: $border_width solid $color;
  border-radius: 50%;
  border-bottom-color: transparent;
  border-left-color: transparent;
  animation: $anim_name $anim_duration ease-out forwards;
}

@mixin circle_keyframe($anim_name, $rotate_from, $rotate_to) {
  @keyframes #{$anim_name} {
    from {
      transform: rotate($rotate_from);
    }
    to {
      transform: rotate($rotate_to);
    }
  }
}

.intro_section {
  position: relative;
  overflow: hidden;
  .intro_container {
    height: 100vh;
    width: 100%;
    position: relative;
    .carousel_custom {
      .carousel_item_custom {
        height: 100vh;
        width: 100%;
        .carousel_img {
          height: 100%;
          width: 100%;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

    .carousel_control_left,
    .carousel_control_right {
      z-index: 200;
      position: absolute;
      top: 50%;
      .control_btn {
        background-color: transparent;
        border: none;
        outline: none;
        .arrow {
          width: auto;
          height: 1.5rem;
          margin: 0.5rem;
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }
    .carousel_control_left {
      left: 3%;
    }
    .carousel_control_right {
      right: 3%;
    }

    .intro_texts {
      position: absolute;
      top: 18%;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 100;
      .intro_subtitle {
        margin-top: 0.8rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        // transform: translateY(50px);
        transition: all 1s ease-out;

        h4 {
          font-family: Mont-Regular;
          font-size: 1.6rem;
          color: #fff;
        }
      }
      .intro_subtitle_animation {
        opacity: 1;
        // transform: translateY(0);
      }
      .intro_du_logo {
        margin-top: 0.8rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transform: translateY(40px);
        transition: all 0.7s ease-out;

        img {
          width: 8rem;
        }
      }

      .intro_logo_animation {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .top_circle_container {
    @include circle_container(top, right, -28rem, -32rem);
    .top_circle {
      @include circle_main(
        80rem,
        80rem,
        120px,
        $light_green_opacity,
        topCircle,
        4s
      );
    }
  }

  .bottom_circle_container {
    @include circle_container(bottom, left, -53rem, -46rem);
    .bottom_circle {
      @include circle_main(
        80rem,
        80rem,
        120px,
        $dark_green_opacity,
        bottomCircle,
        4s
      );
    }
  }
}

@include circle_keyframe(topCircle, -10deg, -180deg);
@include circle_keyframe(bottomCircle, -130deg, -40deg);

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .intro_section {
    .intro_container {
      .intro_texts {
        top: 24%;
        .intro_subtitle {
          margin-top: 0.8rem;
          h4 {
            font-size: 4vw;
          }
        }
        .intro_du_logo {
          margin-top: 0.8rem;
          img {
            width: 6rem;
          }
        }
      }
    }
    .top_circle_container {
      @include circle_container(top, right, -30rem, -34rem);
      .top_circle {
        @include circle_main(60rem, 60rem);
      }
    }

    .bottom_circle_container {
      @include circle_container(bottom, left, -42rem, -39rem);
      .bottom_circle {
        @include circle_main(60rem, 60rem);
      }
    }
  }
}
/* -------- sm -------- */
@media (min-width: 576px) and (max-width: 767.98px) {
  .intro_section {
    .intro_container {
      .intro_texts {
        top: 24%;
        .intro_subtitle {
          margin-top: 0.8rem;
          h4 {
            font-size: 1.4rem;
          }
        }
        .intro_du_logo {
          margin-top: 0.8rem;
          img {
            width: 7rem;
          }
        }
      }
    }
    .top_circle_container {
      @include circle_container(top, right, -41rem, -45rem);
    }

    .bottom_circle_container {
      @include circle_container(bottom, left, -58rem, -55rem);
    }
  }
}
/* -------- md -------- */
@media (min-width: 768px) and (max-width: 991.98px) {
  .intro_section {
    .intro_container {
      .intro_texts {
        top: 20%;
        .intro_subtitle {
          margin-top: 0.8rem;
          h4 {
            font-size: 1.5rem;
          }
        }
        .intro_du_logo {
          margin-top: 0.8rem;
          img {
            width: 7rem;
          }
        }
      }
    }
    .top_circle_container {
      @include circle_container(top, right, -38rem, -42rem);
    }

    .bottom_circle_container {
      @include circle_container(bottom, left, -55rem, -48rem);
    }
  }
}
/* -------- l -------- */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .intro_section {
    .top_circle_container {
      @include circle_container(top, right, -33rem, -37rem);
    }
  }
}
/* -------- xl -------- */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}
/* -------- xxl -------- */
@media (min-width: 1400px) {
}
