@import "../../global/style.scss";

.menubar_mobile {
  .logo_n_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      text-decoration: none;
      .navbar_logo_img {
        width: 5rem;
        height: auto;
        filter: drop-shadow(2px 3px 1.5px rgb(0 0 0 / 0.4));
        transition: all 0.2s ease-out;
      }
      .img_after_scroll {
        width: 3.5rem;
      }
    }
  }
  .menu_main {
    padding: 0 1rem;
    background: white;
    width: 100%;
    height: 110vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.5s ease;
    .menu_main_wrapper {
      margin-top: 8rem;
      a {
        text-decoration: none;
        color: black;
      }
      .accordion_item_custom {
        border: 0;
        .accordion_button_custom {
          padding: 0.8rem 1.25rem !important;
        }
        .accordion_button_custom:focus {
          border: 0 !important;
          border-color: transparent !important;
          box-shadow: none !important;
        }
        .accordion_button_custom:not(.collapsed) {
          color: $light_green;
          background-color: transparent;
          box-shadow: none !important;
        }
        .accordion_button_custom:not(.collapsed)::after {
          background-image: url("../../assets/icons/accordion_green_arrow.svg");
        }
        .non_accordion {
          font-size: 1.3rem;
          font-weight: 500;
          padding: 0.8rem 1.25rem;
        }
        h2 {
          button {
            font-size: 1.3rem;
            font-weight: 500;
          }
        }
      }
      .accordion_body_custom {
        padding-top: 0;
        padding-left: 2rem;
        .accordion_body_item {
          font-size: 1.3rem;
          font-weight: 500;
        }
      }
    }
  }
  .menu_main_show {
    transform: translateX(0);
  }
  .menu_main_hidden {
    transform: translateX(100%);
  }
}
