@import "../../global/style.scss";

.supervisors_page_section {
  .supervisors_page_container {
    .supervisors_page_title {
      text-align: center;
      font-size: 5.5rem;
      font-family: Mont-Heavy;
      color: $light_green;
      margin-bottom: 4rem;
      padding-top: 10rem;
    }
    .supervisors_page_main {
      .supervisors_btn_wrapper {
        text-align: center;
        .supervisor_btn {
          font-size: 1.1rem;
          font-family: Mont-Regular;
          background-color: white;
          border: 0.2rem solid $light_green;
          border-radius: 0.5rem;
          outline: none;
          padding: 0.5rem;
          margin-left: 1rem;
          margin-right: 1rem;
          margin-bottom: 1rem;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          transition: all 0.2s ease-in-out;
          &:hover {
            color: white;
            background-color: $light_green;
          }
        }
        .supervisor_btn_clicked {
          color: white;
          background-color: $light_green;
        }
      }

      .supervisor_single_container {
        width: 95% !important;
        margin: 4rem auto;
        padding: 3rem;
        animation: fadeIn 0.5s ease-out;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        .supervisor_info {
          .supervisor_img_wrapper {
            .supervisor_img {
              width: 100%;
            }
          }

          .info_texts {
            margin-left: 1.2rem;
            text-align: left;
            height: 100%;
            display: flex;
            align-items: center;
            .info_texts_wrapper {
              .title {
                font-size: 2.5rem;
                font-family: Mont-Bold;
                color: $light_green;
              }
              .designation {
                margin-top: 1rem;
                font-size: 1.8rem;
                font-family: Mont-Regular;
              }
              .institution {
                font-size: 1.2rem;
                font-family: Mont-Regular;
              }
              .email {
                font-size: 1rem;
                font-family: Mont-Regular;
              }
            }
          }
        }
        .supervisor_details {
          margin-top: 3rem;
          text-align: justify;
          .supervisor_details_text {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .supervisors_page_section {
    .supervisors_page_container {
      .supervisors_page_title {
        text-align: center;
        font-size: 3rem;
        font-family: Mont-Heavy;
        color: $light_green;
        margin-bottom: 4rem;
        padding-top: 10rem;
      }
      .supervisors_page_main {
        .supervisors_btn_wrapper {
          text-align: center;
          .supervisor_btn {
            font-size: 1.1rem;
            font-family: Mont-Regular;
            background-color: white;
            border: 0.2rem solid $light_green;
            border-radius: 0.5rem;
            outline: none;
            padding: 0.5rem;
            margin-left: 1rem;
            margin-right: 1rem;
            margin-bottom: 1rem;
          }
          .supervisor_btn_clicked {
            color: white;
            background-color: $light_green;
          }
        }

        .supervisor_single_container {
          width: 95% !important;
          margin: 4rem auto;
          padding: 1.5rem;
          animation: fadeIn 0.5s ease-out;
          .supervisor_info {
            .supervisor_img_wrapper {
              display: flex;
              justify-content: center;
              .supervisor_img {
                width: 65%;
              }
            }

            .info_texts {
              margin-left: 0;
              margin-top: 1.2rem;
              text-align: left;
              height: 100%;
              display: flex;
              align-items: center;
              .info_texts_wrapper {
                .title {
                  font-size: 2.2rem;
                  font-family: Mont-Bold;
                  color: $light_green;
                }
                .designation {
                  margin-top: 1rem;
                  font-size: 1.4rem;
                  font-family: Mont-Regular;
                }
                .institution {
                  font-size: 1.2rem;
                  font-family: Mont-Regular;
                }
                .email {
                  font-size: 1rem;
                  font-family: Mont-Regular;
                }
              }
            }
          }
          .supervisor_details {
            margin-top: 3rem;
            font-size: 1rem;
            text-align: justify;
          }
        }
      }
    }
  }
}
/* -------- sm -------- */
@media (min-width: 576px) and (max-width: 767.98px) {
}
/* -------- md -------- */
@media (min-width: 768px) and (max-width: 991.98px) {
}
/* -------- l -------- */
@media (min-width: 992px) and (max-width: 1199.98px) {
}
/* -------- xl -------- */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}
/* -------- xxl -------- */
@media (min-width: 1400px) {
}
