$dark_green: #2a7c4f;
$light_green: #6cb156;

$dark_green_opacity: rgba(42, 124, 79, 0.65);
$light_green_opacity: rgba(99, 148, 88, 0.65);

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.container_custom {
  width: 100%;
  padding-right: 6rem;
  padding-left: 6rem;
  margin-right: auto;
  margin-left: auto;
  transition: all 0.5s ease;
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */

/* -------- xsm -------- */
@media (max-width: 575.98px) {
  .container_custom {
    padding-right: 0.938rem;
    padding-left: 0.938rem;
    width: 95%;
  }
}
/* -------- sm -------- */
@media (min-width: 576px) and (max-width: 767.98px) {
  .container_custom {
    padding-right: 0.938rem;
    padding-left: 0.938rem;
    width: 33.75rem;
  }
}
/* -------- md -------- */
@media (min-width: 768px) and (max-width: 991.98px) {
  .container_custom {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    width: 45rem;
  }
}
/* -------- l -------- */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .container_custom {
    width: 60rem;
  }
}
/* -------- xl -------- */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .container_custom {
    width: 75rem;
  }
}
/* -------- xxl -------- */
@media (min-width: 1400px) {
  .container_custom {
    width: 100%;
  }
}
